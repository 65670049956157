





































































































































































































































































































































::v-deep .cell {
  text-align: center;
  word-break: break-word;
}
.chart_title {
  font-weight: bold;
  color: #555;
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
}
.chart_container {
  display: flex;
  justify-content: center;
}
.region_school_dashboard_page_wrapper {
  .some_details {
    display: flex;
    gap: 1%;
    flex-wrap: wrap;
    padding: 15px;
    .every_part {
      width: calc(96% / 4);
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      min-height: 150px;
      transition: 0.5s;
      border-radius: 8px;
      margin-bottom: 25px;
      &:hover {
        background-color: #eee;
      }
      .title {
        font-weight: bold;
        color: #555;
        font-size: 17px;
      }
      .icon {
        position: absolute;
        top: 10px;
        background-color: #67c23a;
        background-color: #fb404b;
        color: #fff;
        border-radius: 50%;
        right: 10px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        font-size: 14px;
        padding: 4px;
        &.not_active {
          background-color: #fb404b;
        }
        &.active {
          background-color: #67c23a;
        }
      }
    }
  }
}
